<template>
<div name="filter">

<section class="bg-white rounded w-full mx-auto px-8 pt-1 pb-6 mb-3" >
     <ul class=" mb-1 w-full" v-if="showFullView" >

        <li class="border rounded bg-white  border-gray-200 w-1/5"
        v-bind:class="filteredClass.id > 0 ? 'bg-action-blue bg-opacity-75 border-white' : 'bg-gray-100'" >
          <dropdown :options="classDropdown" :selected="filteredClass" v-on:updateOption="classSelect" class="w-56 text-center"
               v-bind:class="filteredClass.id > 0 ? 'text-red-100 ' : ''" >
          ></dropdown>
          
        </li>
 </ul>

       <ul class="mt-6 mb-1 w-full"  >

                       <li  v-for="item in yearDropdown"  :key="item.value" class="inline-block mr-2">
                        <button type="button" @click="filterYearValue(item.value)"
                         class="mb-2 hover:bg-action-blue hover:bg-opacity-75  hover:text-white  font-bold py-2 px-4 rounded"
                          v-bind:class="inYearFilter(item.value) ? 'bg-action-blue bg-opacity-75 text-white': 'bg-gray-100 text-gray-500'"> 
                            <i class="far" v-bind:class="inYearFilter(item.value) ? 'fa-check-square': 'fa-square'" ></i> {{ item.name }}
                          </button>

                      </li>      


 </ul>
   <ul class="mt-6 mb-1 w-full"  >

                       <li  v-for="item in typeDropdown" :key="item.value"  class="inline-block mr-2">
                        <button type="button" @click="filterTypeValue(item.value)"
                        class="hover:bg-action-blue hover:bg-opacity-75  hover:text-white  font-bold py-2 px-4 rounded"
                         v-bind:class="inTypeFilter(item.value) ? 'bg-action-blue bg-opacity-75 text-white': 'bg-gray-100 text-gray-500'"> 
                          <i class="far" v-bind:class="inTypeFilter(item.value) ? 'fa-check-square': 'fa-square'" ></i> {{ item.name }}
                          </button>

                      </li>      


 </ul>
 </section>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
import {
  filterTypeList,
} from "@/assets/js/utils";
import { SchoolService } from "@/services/school.service";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

const dropdown = () => import("@/components/Dropdown.vue");

export default {
  name: "filter",
  mixins: [yearGroupMixin],
  props: {
    fullView: {type: Boolean, required: false},
    classes: {type: Array, required: true },
    filterOpen: {type: Boolean, required: true}
  },
  computed: {
  ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess"
    }),
   showFullView(){
    return this.fullView === undefined ? false : this.fullView 
     },
    yearDropdown() {
      var results = this.years
      return results;
    },
    classDropdown() {
      var results = [{ id: 0, name: "Showing All Classes" }].concat(this.classes);
      this.filteredClass = results[0];
      return results;
    },
    typeDropdown() {
      let typeList = this.$orderBy(this.types, ["id"]);
      return typeList;
    },
  },
  data() {
    return {
      types: filterTypeList(),
      filteredYears: [],
      filteredTypes: [],
      filteredCalendar: {},
      filteredClass: {}
    }
  },
  created() {
  },
  watch: {
    // filterOpen(val, oldVal) {
    //   console.log("OPEN---", val)
    // }
  },
  methods: {
    classSelect(value) {
      this.filteredClass = value;
      this.$emit('classUpdated', value)
    },
    filterYearValue(value) {
       if (this.filteredYears.includes(value)) {
          this.filteredYears.splice(this.filteredYears.indexOf(value), 1);
      } else {
        this.filteredYears.push(value);
      }
      this.$emit('yearsUpdated', this.filteredYears)
    },
    inYearFilter(value) {
      return this.filteredYears.includes(value)
    },
    filterTypeValue(value) {
       if (this.filteredTypes.includes(value)) {
          this.filteredTypes.splice(this.filteredTypes.indexOf(value), 1);
      } else {
        this.filteredTypes.push(value);
      }

      this.$emit('typesUpdated', this.filteredTypes)
    },
    inTypeFilter(value) {
      return this.filteredTypes.includes(value)
    },
  },
  components: {
    dropdown
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
